body {
    margin: 0px;
    padding: 0px;
}

/* iframe's parent node */
div#root {
    position: fixed;
    width: 100%;
    height: 100%;
}

/* iframe itself */
div#root > iframe {
    display: block;
    width: 100%;
    height: 100%;
    border: none;
}
